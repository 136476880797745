import React from 'react'
import Badge from 'components/FormatCell/Types/Badge'
import Area from 'components/FormatCell/Types/Area'
import AnimalGender from 'components/FormatCell/Types/Gender'
import Link from 'components/FormatCell/Types/Link'
import Timestamp from 'components/FormatCell/Types/Timestamp'
import Tag from 'components/FormatCell/Types/Tag'
import Boolean from 'components/FormatCell/Types/Boolean'
import Avatar from 'components/FormatCell/Types/Avatar'
import BatteryLevel from 'components/FormatCell/Types/Battery'
import ShowMore from 'components/FormatCell/Types/ShowMore'
import AnimalStatus from 'components/FormatCell/Types/AnimalStatus'
import NumericDifferencer from './Types/NumericDifferencer'
import ButtonForm from './Types/ButtonForm'
import StatusIcon from './Types/Status'
import AnimalInfo from './Types/AnimalInfo'
import CardInfo from './Types/CardInfo'
import HealthStatus from './Types/HealthStatus'
import InfoIcon from './Types/InfoIcon'
import BatteryVariation from './Types/BatteryVariation'

function FormatCell(props) {
  const { value, title, type, ...rest } = props

  switch (type) {
    case 'area':
      return <Area {...props} {...rest} />

    case 'animal-gender':
      return <AnimalGender {...props} {...rest} />

    case 'badge-color':
      return <Badge type={'color'} value={value} {...rest} />

    case 'tag':
      return <Tag value={value} {...rest} />

    case 'timestamp':
      return <Timestamp {...props} {...rest} />

    case 'link':
      return <Link {...props} {...rest} />

    case 'boolean':
      return <Boolean {...props} {...rest} />

    case 'avatar':
      return <Avatar {...props} {...rest} />

    case 'battery-level':
      return <BatteryLevel {...props} {...rest} />

    case 'show-more':
      return <ShowMore {...props} {...rest} />

    case 'numeric-differencer':
      return <NumericDifferencer {...props} {...rest} />

    case 'button-form':
      return <ButtonForm {...props} {...rest} />

    case 'status':
      return <StatusIcon {...props} {...rest} />

    case 'animal-status':
      return <AnimalStatus {...props} {...rest} />

    case 'animal-info':
      return <AnimalInfo {...props} {...rest} />

    case 'card-info':
      return <CardInfo {...props} {...rest} />

    case 'health-status':
      return <HealthStatus {...props} {...rest} />

    case 'info-icon':
      return <InfoIcon {...props} {...rest} />

    case 'battery-variation':
      return <BatteryVariation {...props} {...rest} />
    default:
      return value
  }
}

export default FormatCell
