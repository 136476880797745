import React, { useState } from 'react'
import { Button, Col, Row, List, Modal, message } from 'antd'
import { EyeOutlined, CopyOutlined } from '@ant-design/icons'

const ShowMore = ({ value, header }) => {
  const token = JSON.parse(localStorage.getItem('instabov_token'))
  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  if (!value) return '-'

  return (
    <>
      <Button shape="circle" icon={<EyeOutlined />} onClick={showDrawer} />
      <Modal title={header} footer={null} open={open} onCancel={onClose}>
        <Row>
          <Col span={24}>
            <List
              dataSource={value}
              renderItem={({ name, type, value, values }) => {
                if (type === 'buttons') {
                  return (
                    <Row justify="center" gutter={[16, 16]} style={{ marginTop: 15 }}>
                      {values?.map(item => (
                        <Col key={item.name}>
                          <Button
                            type="primary"
                            onClick={async () => {
                              try {
                                message.warning('Iniciando o download')
                                const response = await fetch(item.value, {
                                  method: 'GET',
                                  headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                  },
                                })

                                if (!response.ok) {
                                  throw new Error(
                                    `Erro ao baixar o arquivo: ${response.status} ${response.statusText}`,
                                  )
                                }

                                const blob = await response.blob()
                                const url = window.URL.createObjectURL(blob)
                                const link = document.createElement('a')
                                link.href = url
                                const filename =
                                  item.achiveName ||
                                  (item.name === 'Download Key' ? 'file.key' : 'file.crt')
                                link.setAttribute('download', filename)
                                document.body.appendChild(link)
                                link.click()
                                link.remove()
                                window.URL.revokeObjectURL(url)
                                message.success('Download concluido')
                              } catch (error) {
                                message.error('O download falhou')
                              }
                            }}
                          >
                            {item.name}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  )
                }
                return (
                  <List.Item>
                    <p
                      style={{
                        overflowWrap: 'anywhere',
                      }}
                    >
                      <strong style={{ textTransform: 'uppercase' }}>{`${name}: `}</strong>
                      {type === 'link' ? <a href={value}>{value}</a> : value}
                    </p>
                    {type === 'copy' ? (
                      <Button
                        type="ghost"
                        size="small"
                        shape="circle"
                        icon={<CopyOutlined />}
                        onClick={() => navigator.clipboard.writeText(value)}
                      />
                    ) : null}
                    {type === 'button' ? (
                      <Button
                        type="ghost"
                        size="small"
                        shape="circle"
                        icon={<CopyOutlined />}
                        onClick={() => navigator.clipboard.writeText(value)}
                      />
                    ) : null}
                  </List.Item>
                )
              }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

export default ShowMore
